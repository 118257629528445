@import '../../components/GlobalStyles/Response.module.scss';


.wrapper{
    display: flex;
    flex-direction: column;

}


.main-content{
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.header {
    height: var(--default-layout-header-height);
    position: fixed;
    top: 0;
    background-color: #0f171c;
    color: #fff;
    width: 100%;
    z-index: 10;
    @include sm{
        height: var(--default-layout-header-height-sm);
    }
}

.content{
    // // padding-top: var(--default-layout-header-height);
    // background-color: #ccc;
    // width: 100%;

    // @include sm{
    //     margin-top: var(--default-layout-header-height-sm);
    //     padding-top: 0;
    // }

}

.footer{
    padding: 48px 96px;
    height: max-content;

    @include sm{
        padding: 32px 16px;
        margin-bottom: 32px;
    }
}


