@import '../../components/GlobalStyles/Response.module.scss';



.btn-open-account {
    @include sm {
        width: 100%;
        margin: 20px 0;
    }
    background-color: #ffcf01;
    border-radius: 4px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 10px 24px;
    height: 48px;
    color: #0f171c;
    font-size: 16px;
    font-weight: 600;
    width: max-content;
    &:hover {
        background-color: #ffda39;
    }
}