@import '../../components/GlobalStyles/Response.module.scss';

.container {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    color: #ffcf01;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-align: start;
    width: 100%;
    margin-top: 24px;
}

.image {
    margin-top: 10px;
    width: 100%;
    height: 350px;
    display: flex;
    > img {
        width: 100%;
        border-radius: 8%;
    }
}

.description {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    text-align: justify;
    margin-top: 8px;
}


@include lg {
    .container {
        max-width: 240px;
    }
    
    .header {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.4;
        margin-top: 24px;
    }
    
    .image {
        margin-top: 10px;
        height: 240px;
    }
    
    .description {
        font-size: 16px;
        margin-top: 8px;
    }
}

@include md {
    .container {
        max-width: 200px;
    }
    
    .header {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.4;
        margin-top: 24px;
    }
    
    .image {
        margin-top: 10px;
        height: 200px;
    }
    
    .description {
        font-size: 16px;
        margin-top: 8px;
    }
}

@include sm {
    .container {
        margin-top: 20px;
        max-width: 80%;
    }

    .header {
        margin-top: 8px;

        font-size: 20px;
    }

    .image {
        height: 300px;
    }

    .description {
        font-size: 16px;
    }
}
