@import '../../../components/GlobalStyles/Response.module.scss';

.start-container {
    width: 100%;
    height: 430px;
    background-image: url(../../../assets/images/CTA-Image-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 96px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    .content {
        color: #fff;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
    }
    .content-header {
        line-height: 1.45;
        font-size: 32px;
    }

    .content-text {
        font-size: 14px;
        font-weight: 400;
        margin-top: 30px;
        color: #fff;
        opacity: 0.6;
    }

    @include sm {
        padding: 32px 16px 8px;
        height: auto;
        .content-header {
            font-size: 28px;
        }
        .content-text {
            font-size: 12px;
        }
    }

    .btn-open-account {
        @include sm {
            width: 100%;
            margin: 20px 0;
        }
        background-color: #ffcf01;
        border-radius: 4px;
        cursor: pointer;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 24px;
        height: 48px;
        color: #0f171c;
        font-size: 16px;
        font-weight: 600;
        width: max-content;
        &:hover {
            background-color: #ffda39;
        }
    }
}
