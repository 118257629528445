@import '../../components/GlobalStyles/Response.module.scss';

.wrapper {
}

.banner {
    width: 100%;
    height: auto;
    margin-top: 54px;

    img {
        width: 100%;
        object-fit: cover;
    }
}

.introduce-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 60px;
    background-color: #0f171c;

    .introduce-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
    }

    .content-container {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex: 10;
        max-width: 1500px;
    }

    .introduce-content {
        margin-top: 30px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;
        flex: 7;
    }

    .image-container {
        display: flex;
        padding-top: 20px;
        flex-direction: column;
        justify-content: center;
        flex: 3;

        .header {
            width: 100%;
            align-items: center;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #ffcf01;
            margin-bottom: 15px;
            > p {
                width: 100%;
            }
        }

        .img {
            width: 100%;
        }

        img {
            flex-shrink: 0;
        }
    }

    .concept-container {
        margin-top: 35px;
        margin-right: 20px;
        display: flex;
    }

    .icon {
        margin-top: 2px;
        margin-right: 15px;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
    }

    .concept {
        > i {
            color: #ffcf01;
        }

        > span {
            color: #ffcf01;
        }

        > ul {
            text-decoration: none;
            list-style: none;
            font-style: italic;
            font-size: 22px;
            opacity: 0.8;
            margin-left: 20px;
        }

        a {
            margin-left: 8px;
            font-weight: 500;
            font-size: 28px;
            color: #ffda39;
            position: relative;
            text-decoration: none;

            &:hover {
                color: #ffcf01;
            }
        }

        a::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #ffda39;
        }
    }

    @include md {
        text-align: justify;
        padding: 40px 32px 20px;
        .introduce-header {
            font-size: 24px;
        }

        .content-container {
            flex-direction: column;
        }

        .introduce-content {
            margin-top: 20px;
            font-size: 16px;
        }

        .concept-container {
            margin-top: 10px;
        }

        .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        .concept {
            > i {
                font-size: 20px;
            }

            > ul {
                font-size: 16px;
                margin-left: 10px;
            }

            a {
                font-size: 20px;
            }

            a::after {
                height: 1px;
            }
        }

        .image-container {
            padding-top: 20px;
            display: flex;
            align-items: center;

            .header {
                width: 100%;
                font-size: 18px;
                margin-bottom: 0px;
            }

            .img {
                width: 60%;
            }
        }
    }

    @include sm {
        padding: 40px 16px 20px;
        .introduce-header {
            width: 100%;
            font-size: 24px;
        }

        .content-container {
            flex-direction: column;
        }

        .introduce-content {
            margin-top: 20px;
            font-size: 16px;
        }

        .concept-container {
            margin-top: 10px;
        }

        .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        .concept {
            > i {
                font-size: 20px;
            }

            a {
                font-size: 20px;
            }

            a::after {
                height: 1px;
            }
        }

        .image-container {
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .header {
                font-size: 16px;
            }

            .img {
                width: 80%;
            }
        }

        .mySwiper {
            width: 100%;
            overflow: 'hidden';
            border-radius: 10px;

            img {
                object-fit: contain;
                width: 100%;
                border-radius: 10px;
            }

            .swiper-button-prev,
            .swiper-button-next {
                background-color: yellow;
                border-radius: 50%;
            }
        }
    }
}

.why-choice-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 60px;
    background-color: #0f171c;

    .why-choice-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
    }

    .content-container {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex: 10;
        max-width: 1500px;
    }

    .why-choice-content {
        margin-top: 30px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        line-height: 1.4;
        flex: 7;
    }

    .image-container {
        display: flex;
        width: 150px;
        padding-top: 20px;
        flex-direction: column;
        justify-content: center;
        flex: 2;
        align-items: center;

        .header {
            width: 100%;
            align-items: center;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #ffcf01;
            margin-bottom: 15px;
            > p {
                width: 100%;
            }
        }

        .img {
            width: 60%;
        }

        img {
            flex-shrink: 0;
        }
    }

    .swiper {
        width: 100%;
    }

    .mySwiper {
        width: 100%;
        overflow: hidden;
        border-radius: 10px;

        img {
            object-fit: contain;
            width: 100%;
            border-radius: 10px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            background-color: yellow;
            border-radius: 50%;
        }
    }

    

    .concept-container {
        margin-top: 35px;
        margin-right: 20px;
        display: flex;
    }

    .icon {
        margin-top: 2px;
        margin-right: 15px;
        width: 32px;
        height: 32px;
        flex-shrink: 0;
    }

    .concept {
        > i {
            color: #ffcf01;
        }

        > span {
            color: #ffcf01;
        }

        > ul {
            text-decoration: none;
            list-style: none;
            font-style: italic;
            font-size: 22px;
            opacity: 0.8;
            margin-left: 20px;
        }

        a {
            margin-left: 8px;
            font-weight: 500;
            font-size: 28px;
            color: #ffda39;
            position: relative;
            text-decoration: none;

            &:hover {
                color: #ffcf01;
            }
        }

        a::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #ffda39;
        }
    }

    @include md {
        text-align: justify;
        padding: 40px 32px 20px;
        .why-choice-header {
            font-size: 28px;
        }

        .content-container {
            flex-direction: row;
        }

        .why-choice-content {
            margin-top: 20px;
            font-size: 16px;
        }

        .concept-container {
            margin-top: 10px;
        }

        .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        .concept {
            > i {
                font-size: 20px;
            }

            > ul {
                font-size: 16px;
                margin-left: 10px;
            }

            a {
                font-size: 20px;
            }

            a::after {
                height: 1px;
            }
        }

        .image-container {
            padding-top: 20px;
            display: flex;
            align-items: center;

            .header {
                width: 100%;
                font-size: 18px;
                margin-bottom: 0px;
            }

            .img {
                width: 50%;
            }
        }
    }

    @include sm {
        padding: 40px 16px 20px;
        .why-choice-header {
            width: 100%;
            font-size: 24px;
        }

        .content-container {
            flex-direction: column;
        }

        .why-choice-content {
            margin-top: 20px;
            font-size: 16px;
        }

        .concept-container {
            margin-top: 10px;
        }

        .icon {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }

        .concept {
            > i {
                font-size: 20px;
            }

            a {
                font-size: 20px;
            }

            a::after {
                height: 1px;
            }
        }

        .image-container {
            width: 100%;
            margin-top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .header {
                font-size: 16px;
            }

            .img {
                width: 80%;
            }
        }
        .mySwiper {
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
    
            img {
                object-fit: contain;
                width: 100%;
                border-radius: 10px;
            }
    
            .swiper-button-prev,
            .swiper-button-next {
                background-color: yellow;
                border-radius: 50%;
            }
        }
    }
}

.service-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 60px;
    background-color: #0f171c;

    .service-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
    }

    .content-container {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex: 10;
        max-width: 1500px;
        margin-top: 30px;
    }

    .concept-container {
        margin-top: 35px;
        margin-right: 20px;
        display: flex;
    }

    @include lg {
        .service-header {
            font-size: 28px;
        }
    }

    @include sm {
        padding: 40px 16px 20px;
        .service-header {
            font-size: 24px;
            width: 100%;
        }
        .content-container {
            display: flex;
            width: 100%;
            flex: 10 1;
            max-width: 1500px;
            margin-top: 0px;
            flex-direction: column;
            align-items: center;
        }

        .concept-container {
            margin-top: 35px;
            margin-right: 20px;
            display: flex;
        }
    }
}

.copy-trade-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 60px 60px;
    background-color: #0f171c;

    .copy-trade-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 60px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
    }

    .intro-container {
        margin-top: 24px;
        font-size: 24px;
        color: #fff;
        max-width: 1500px;

        ul {
            text-decoration: none;
            list-style: none;
            font-style: italic;
            font-size: 22px;
            margin-left: 24px;
        }
    }

    .content-container {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex: 10;
        max-width: 1500px;
        margin-top: 30px;
    }

    .concept-container {
        margin-top: 35px;
        margin-right: 20px;
        display: flex;
    }

    @include lg {
        padding: 40px 16px 20px;
        .copy-trade-header {
            font-size: 28px;
        }
        .link {
            margin-left: 50px;
            font-size: 26px;
            a {
                margin-left: 8px;
                font-size: 30px;
            }
        }
        .intro-container {
            margin-top: 24px;
            font-size: 18px;
            ul {
                font-size: 16px;
                margin-left: 24px;
            }
        }
    }

    @include sm {
        padding: 40px 16px 20px;
        .copy-trade-header {
            font-size: 24px;
            width: 100%;
        }
        .content-container {
            display: flex;
            width: 100%;
            flex: 10 1;
            max-width: 1500px;
            margin-top: 0px;
            flex-direction: column;
            align-items: center;
        }

        .intro-container {
            margin-top: 24px;
            font-size: 18px;
            ul {
                font-size: 16px;
                margin-left: 8px;
            }
        }

        .concept-container {
            margin-top: 35px;
            margin-right: 20px;
            display: flex;
        }
        .link {
            margin-top: 16px;
            margin-left: 30px;
            font-size: 22px;
            a {
                margin-left: 8px;
                font-size: 26px;
                color: #ffda39;
            }
        }
    }
}

.banner-container {
    background-color: #0f171c;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .banner {
        max-width: 1500px;

        width: 100%;
    }

    .link-container {
        padding: 24px 60px;
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .link {
        width: 100%;
        color: #ffcf01;
        font-size: 34px;
        a {
            margin-left: 8px;
            font-weight: 500;
            font-size: 34px;
            color: #ffda39;
            position: relative;
            text-decoration: none;

            &:hover {
                color: #ffcf01;
            }
        }

        a::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #ffda39;
        }
    }
    @include lg {
        .link {
            font-size: 26px;
            a {
                margin-left: 8px;
                font-size: 30px;
            }
        }
    }
    @include sm {

        .link-container {
            padding: 16px 16px 20px;

            max-width: 1500px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .link {
            font-size: 22px;
            a {
                margin-left: 8px;
                font-size: 26px;
                color: #ffda39;
            }
        }
    }

}

.registration-instruction-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 40px 60px;
    background-color: #0f171c;
    color: #fff;
    font-size: 24px;

    .registration-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
        width: max-content;
    }

    .registration-content {
        margin-left: 50px;
        margin-right: 20px;
    }

    .registration-way {
        font-size: 30px;
        margin-top: 30px;
        b {
            font-size: 32px;
            color: #ffcf01;
        }
    }

    .registration-content-container {
        width: 100%;
        max-width: 1500px;
    }

    .content-container {
        margin-left: 30px;
    }

    .content {
        margin-top: 10px;

        .sub-title {
            padding-left: 48px;
        }

        > ul {
            text-decoration: none;
            list-style: none;
            font-style: italic;
            font-size: 22px;
            opacity: 0.8;
            margin-left: 35px;
        }

        img {
            width: 40%;
            padding-left: 48px;
            padding-top: 16px;
        }

        > div {
            padding-left: 24px;
            > p {
                padding-left: 48px;
                color: #ffcf01;
                > i {
                    opacity: 0.7;
                    color: #fff;
                }
            }
        }

        > p {
            > b {
                color: #ffcf01;
                font-size: 28px;
            }

            i {
                color: #e5cc5e;
            }

            > span {
                word-break: break-all;
            }

            a {
                margin-left: 5px;
                font-weight: 500;
                font-size: 28px;
                color: #ffda39;
                position: relative;
                text-decoration: none;

                &:hover {
                    color: #ffcf01;
                }
            }

            a::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: #ffda39;
            }
        }
    }
    .note {
        margin-left: 25px;
        margin-top: 20px;
        font-style: italic;
        font-size: 20px;
        opacity: 0.8;
    }

    @include lg {
        padding: 40px 16px 20px;

        .registration-header {
            font-size: 28px;
        }

        .registration-content-container {
            padding-left: 24px;
            padding-right: 24px;
        }

        .registration-content {
            margin-left: 0px;
            line-height: 1.3;
        }

        .registration-way {
            font-size: 22px;
            line-height: 1.3;
            b {
                font-size: 24px;
                color: #ffcf01;
            }
        }

        .content-container {
            margin-left: 20px;
            margin-top: 10px;
        }

        .content {
            margin-top: 10px;
            font-size: 16px;

            .sub-title {
                padding-left: 0px;
            }

            > ul {
                font-size: 16px;
                margin-left: 5px;
            }

            img {
                width: 60%;
                padding-left: 32px;
                padding-top: 10px;
            }

            > div {
                padding-left: 0px;
                > p {
                    padding-left: 0px;
                }
            }

            > p {
                > b {
                    font-size: 22px;
                }

                a {
                    font-size: 22px;
                }
            }
        }
        .note {
            margin-left: 20px;
            margin-top: 10px;
            font-size: 14px;
        }
    }

    @include sm {
        padding: 40px 16px 20px;

        .registration-header {
            font-size: 28px;
            width: 100%;
        }

        .registration-content {
            margin-left: 0px;
            line-height: 1.3;
        }

        .registration-way {
            font-size: 22px;
            line-height: 1.3;
            b {
                font-size: 24px;
                color: #ffcf01;
            }
        }

        .registration-content-container {
            padding-left: 0px;
            padding-right: 0px;
        }

        .content-container {
            margin-left: 20px;
            margin-top: 10px;
        }

        .content {
            margin-top: 10px;
            font-size: 16px;

            .sub-title {
                padding-left: 0px;
            }

            > ul {
                font-size: 16px;
                margin-left: 5px;
            }

            img {
                width: 100%;
                padding-left: 0px;
                padding-top: 10px;
            }

            > div {
                padding-left: 0px;
                > p {
                    padding-left: 0px;
                }
            }

            > p {
                > b {
                    font-size: 22px;
                }

                a {
                    font-size: 22px;
                }
            }
        }
        .note {
            margin-left: 20px;
            margin-top: 10px;
            font-size: 14px;
        }
    }
}
