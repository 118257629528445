@import '../../../components/GlobalStyles/Response.module.scss';
// @import '../../../assets/images/ExnessLogoWhite.svg';

.wrapper {
    height: 100%;
}

.inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0px 25px;
    position: relative;
}

.container-logo {
    height: 40px;
    display: flex;
    align-items: center;
}

.logo-peace {
    width: 36px;
    margin-left: 8px;
}

.big-logo {
    height: 30px;
    margin-left: 50px;
}

.logo-xm {
}

.btn-open-account {
    background-color: #ffcf01;
    border-radius: 4px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 10px 24px;
    height: 48px;
    color: #0f171c;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    width: max-content;
    &:hover {
        background-color: #ffda39;
    }
}

.container-balloon {
    height: 100px;
    width: 100px;
    position: absolute;
    right: 0px;
    bottom: 180px;
    position: fixed;

    .messenger-balloon,
    .telegram-balloon {
        cursor: pointer;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
    }
    .messenger-balloon {
        width: 105%;
        margin-bottom: -12px;
        background-image: url(../../../assets/images/IconMesseger.png);
    }
    .telegram-balloon {
        background-image: url(../../../assets/images/IconTelegram.png);
    }
    .zalo-balloon {
        height: 75%;
        // width: 110%;
        background-image: url(../../../assets/images/IconZalo.png);
        background-position: center; /* Hiển thị ảnh ở giữa theo chiều ngang */
        background-size: contain; /* Giữ nguyên tỷ lệ của ảnh và hiển thị trong kích thước .zalo-balloon */
        background-repeat: no-repeat;
        margin-right: 6px;
        cursor: pointer;
    }
}

@include xl {
    .big-logo {
        margin-left: 30px;
    }

    .big-logo {
        margin-left: 80px;
    }

    .container-balloon {
        right: 0;
        bottom: 185px;
    }
}

@include lg {
    .inner {
        justify-content: space-evenly;
    }
    .btn-open-account {
        display: none;
    }

    .big-logo {
        margin-left: 50px;
    }
    
}

@include sm {
    .inner {
        justify-content: center;
        padding: 0px 10px;
    }
    .btn-open-account {
        display: none;
    }

    .container-logo {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .big-logo {
            margin-left: 0px;
        }
        .logo-peace {
            display: none;
        }

        > a > img {
            height: 25px;
        }
    }

    .container-balloon {
        height: 65px;
        width: 65px;
        bottom: 115px;

        .messenger-balloon,
        .telegram-balloon {
            cursor: pointer;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
        }
        .messenger-balloon {
            width: 105%;
            background-image: url(../../../assets/images/IconMesseger.png);
        }
        .telegram-balloon {
            background-image: url(../../../assets/images/IconTelegram.png);
        }
    }
}