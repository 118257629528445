@import 'normalize.css';
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");


:root {
    --default-layout-header-height: 64px;
    --default-layout-header-height-sm: 56px;
    --default-layout-Sidebar-width: 300px;
    --default-layout-content: 1152px;

    --default-layout-footer-height: 452px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;

}

body {
    height: max-content;
    font-family: "DM Sans", sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;

}


option {
    color: black;
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a[href] {
    color: var(--text-color);
    text-decoration: none;
}

.no-select {
    user-select: none; /* Chuẩn hiện tại */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

