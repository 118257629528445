@import '../../../components/GlobalStyles/Response.module.scss';

.wrapper {
}

.line {
    height: 1px;
    border-color: #0f171c1f;
    border-width: 0;
    border-style: solid;
    border-bottom-width: thin;
    margin-bottom: 48px;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.container-logo-icon {
}

.logo {
    display: flex;
}

.icon {
    > a > * {
        margin-top: 16px;
        width: 32px;
    }

    :not(:last-child) {
        margin-right: 8px;
    }
}

.logo-peace {
    width: 36px;
    margin-left: 8px;
}

.copyright {
    color: #8d9194;
    width: max-content;
    font-size: 13px;
    text-align: right;
}

@include sm {
    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }

    .line {
        height: 2px;
        width: 100%;
    }

    .copyright {
        margin-top: 30px;
    }
}
