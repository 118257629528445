@import '../../components/GlobalStyles/Response.module.scss';

.container {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    // color: #fff;
    font-size: 34px;
    font-weight: 700;
    line-height: 1.4;
}

.image {
    margin-top: 10px;
    width: 100%;

    > img {
        width: 100%;
        border-radius: 8%;
    }
}

.description {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    text-align: justify;
}

@include lg {
    .container {
        max-width: 250px;
    }

    .header {
        font-size: 26px;
    }

    .image {
    }

    .description {
        font-size: 16px;
    }
}

@include md {
    .container {
        max-width: 250px;
    }

    .header {
        font-size: 26px;
    }

    .image {
    }

    .description {
        font-size: 16px;
    }
}

@include sm {
    .container {
        margin-top: 20px;
        max-width: 80%;
    }

    .header {
        font-size: 26px;
    }

    .image {
    }

    .description {
        font-size: 16px;
    }
}
