@import '../../../components/GlobalStyles/Response.module.scss';

.identify-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 40px 60px;
    background-color: #0f171c;
    line-height: 1.3;
    .identify-header {
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
        width: max-content;

        a {
            margin-left: 10px;
            font-weight: 500;
            color: #44b5e7;
            position: relative;
            text-decoration: none;
            font-style: italic;
            text-shadow: none;

            &:hover {
                color: #abe1e7;
            }
        }

        a::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: #44b5e7;
        }
    }

    .content-container {
        margin-top: 30px;

        display: flex;
        width: 100%;
        object-fit: contain;
        img {
            width: 100%;
            border-radius: 10px;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-slide {
            text-align: center;
            font-size: 18px;
            background: #fff;

            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include lg {
        .identify-header {
            width: 60%;
            font-size: 22px;
        }
    }

    @include sm {
        display: block;
        padding: 32px 16px 8px;

        .identify-header {
            padding: 10px 20px;
            font-size: 22px;
            width: 100%;
            a {
                margin-left: 7px;
            }
        }

        .content-container {
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}