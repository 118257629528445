@import '../../../components/GlobalStyles/Response.module.scss';

.welcome-container {
    width: 100%;
    height: 672px;
    margin-top: 64px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    .container-content {
        width: 100%;
        color: #000000;
        white-space: pre-line;
        display: flex;
        flex-direction: row;
    }

    .content {
        display: flex;
        padding-left: 70px;
        padding-right: 70px;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        .content-header {
            font-size: 50px;
            font-weight: 900;
            color: #ffcf01;
            font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
            .logo {
                width: 70px;
            }
        }

        .content-description {
            line-height: 1.45;
            font-size: 32px;
            margin-top: 20px;
        }

        .content-text {
            font-size: 24px;
            font-style: italic;
            font-weight: 400;
            font-family: 'Times New Roman', Times, serif;
            p {
                margin-top: 30px;
                i {
                    font-size: 36px;
                    font-weight: 600;
                    color: #ffc600;
                    -webkit-text-stroke: 1px #000000;
                    // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
                    // text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
                }
                i:last-child {
                    color: #ff2301;
                    text-shadow: none  ;
                    -webkit-text-stroke: #ffffff ;

                }
            }
        }
    }

    .image-content {
        width: 50%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    @include xl {
        display: none;
        height: 550px;
        margin-top: 64px;
        .content {
            padding-left: 20px;
            padding-right: 20px;
            .content-description {
                margin-top: 0px;
            }

            .content-text {
                p {
                    margin-top: 10px;
                }
            }
        }
    }

    @include lg {
        height: 450px;
        margin-top: 64px;
        .container-content {
            width: 100%;
        }

        .content {
            .content-header {
                font-size: 40px;
                .logo {
                    width: 60px;
                }
            }

            .content-description {
                margin-top: 25px;
                font-size: 22px;
            }

            .content-text {
                font-size: 18px;
                p {
                    margin-top: 10px;
                    i {
                        font-size: 22px;
                    }
                }
            }
        }
    }

    @include sm {
        background-image: url(../../../assets/images/backgr-moblie.jpg);
        padding: 32px 16px;
        justify-content: flex-end;
        margin-top: 56px;
        height: 650px;
        .container-content {
            width: 100%;
            color: #ffffff;
            white-space: pre-line;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .content {
            display: flex;
            padding-left: 0px;
            padding-right: 0px;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            .content-header {
                font-size: 40px;
                .logo {
                    width: 60px;
                }
            }

            .content-description {
                line-height: 1.45;
                font-size: 26px;
                margin-top: 16px;
            }

            .content-text {
                font-size: 18px;
                p {
                    margin-top: 8px;
                    i {
                        font-size: 24px;
                        color: #ffcf01;
                    }
                    i:last-child {
                        color: #ff2301;
                    }
                }
            }
        }
        .image-content {
            display: none;
        }
    }
}
