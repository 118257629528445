@import '../../components/GlobalStyles/Response.module.scss';

// common styles
.title-page-x {
    color: #ff2301;
    font-style: normal;
    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
}

.title-page-m {
    color: #ffffff;
    font-weight: 700;
    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
}

.wrapper {
}

// .registration-instruction-container:first-child {
//     padding: 30px 40px 0px;
// }

.registration-instruction-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 40px 60px;
    background-color: #0f171c;
    color: #fff;
    font-size: 24px;

    .registration-header {
        margin-top: 16px;
        background-color: #505050;
        border: 5px #ffcf01 solid;
        border-radius: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        padding: 10px 40px;
        color: #ffcf01;
        font-size: 34px;
        font-weight: 600;
        text-align: center;
        // text-shadow: 1px 1px 2px #c4baba, 0 0 25px #939393, 0 0 5px #000000;
        width: max-content;
        > i {
            color: #ff2301;
            font-style: normal;
            text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
        }
        > b {
            color: #ffffff;
            font-weight: 700;
            text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
        }
    }

    .registration-description {
        line-height: normal;
        margin-top: 24px;
        > p {
            > i {
                color: #ff2301;
                font-style: normal;
                text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
            }
            > b {
                color: #ffffff;
                font-weight: 700;
                text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
            }
        }
    }

    .registration-content {
        margin-right: 20px;
    }

    .registration-content-container {
        width: 100%;
        max-width: 1500px;
    }

    .content-container {
        margin-left: 30px;
    }

    .content {
        margin-top: 10px;

        .sub-title-way {
            > p {
                > a {
                    color: #e5cc5e;
                }
                > b {
                    color: #e5cc5e;
                }
            }
            > ul {
                text-decoration: none;
                list-style: none;
                font-style: italic;
                font-size: 22px;
                opacity: 0.8;
                margin-left: 35px;
            }
        }

        img {
            width: 40%;
            padding-left: 48px;
            padding-top: 16px;
        }

        > b {
            font-size: 26px;
            > strong {
                color: #ffcf01;
                font-size: 32px;
            }

            i {
                color: #e5cc5e;
            }

            > span {
                word-break: break-all;
            }

            a {
                margin-left: 5px;
                font-weight: 500;
                font-size: 28px;
                color: #ffda39;
                position: relative;
                text-decoration: none;

                &:hover {
                    color: #ffcf01;
                }
            }

            a::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: #ffda39;
            }
        }
    }
    .note {
        margin-left: 25px;
        margin-top: 20px;
        font-style: italic;
        font-size: 20px;
        opacity: 0.8;
    }

    @include lg {
        // padding: 40px 16px 20px;

        .registration-header {
            font-size: 28px;
        }

        .registration-content-container {
            padding-right: 0px;
            padding-left: 0px;
        }

        .registration-description {
            margin-top: 16px;
            font-size: 22px;
            > p {
                > i {
                    color: #ff2301;
                    font-style: normal;
                    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
                }
                > b {
                    color: #ffffff;
                    font-weight: 700;
                    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
                }
            }
        }

        .registration-content {
            margin-left: 0px;
            margin-right: 0px;
            line-height: 1.3;
        }

        .content-container {
            margin-left: 8px;
            margin-top: 10px;
        }

        .content {
            margin-top: 10px;
            font-size: 18px;

            .sub-title-way {
                > p {
                    > a {
                        color: #e5cc5e;
                    }
                    > b {
                        color: #e5cc5e;
                    }
                }
                > ul {
                    font-size: 18px;
                    margin-left: 5px;
                }
            }

            img {
                width: 60%;
            }

            > b {
                font-size: 22px;
                > strong {
                    font-size: 24px;
                }
                > a {
                    font-size: 18px;
                }
            }
        }
    }

    @include sm {
        padding: 40px 16px 20px;

        .registration-header {
            font-size: 28px;
            width: 100%;
        }

        .registration-content-container {
            padding-right: 0px;
            padding-left: 0px;
        }

        .registration-description {
            text-align: justify;

            margin-top: 16px;
            font-size: 22px;
            > p {
                > i {
                    color: #ff2301;
                    font-style: normal;
                    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
                }
                > b {
                    color: #ffffff;
                    font-weight: 700;
                    text-shadow: 1px 1px 2px #dd3030, 0 0 25px #cf2828, 0 0 5px #e21e1e;
                }
            }
        }

        .registration-content {
            margin-left: 0px;
            margin-right: 0px;
            line-height: 1.3;
        }

        .content-container {
            margin-left: 8px;

            margin-top: 10px;
        }

        .content {
            margin-top: 10px;
            font-size: 16px;

            .sub-title-way {
                > p {
                    > a {
                        color: #e5cc5e;
                    }
                    > b {
                        color: #e5cc5e;
                    }
                }
                > ul {
                    font-size: 16px;
                    margin-left: 5px;
                }
            }

            img {
                width: 100%;
                padding-left: 0px;
                padding-top: 10px;
            }

            > b {
                font-size: 20px;
                > strong {
                    font-size: 22px;
                }
                > a {
                    font-size: 16px;
                }
            }
        }
    }
}
